import {
  ExpandMoreRounded,
  ExpandLessRounded,
  CloseRounded,
  SearchRounded,
} from '@mui/icons-material';
import {
  IconButton,
  Typography,
  Popper,
  Button,
  Collapse,
  InputBase,
  Modal,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState, useMemo, useEffect } from 'react';
import { BasicButton, TertiaryButton } from '../../atoms/Button/index';
import recommendationCategories from '../../../assets/newRecommendationCategories.json';
import { EVENTS, phTrackEvent } from '../../../analytics';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  // Categories
  popper: {
    height: 'calc(100% - 90px)',
    zIndex: 1301,
    bottom: 0,
    marginTop: '8px',
    [breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,
      height: '100%',
    },
  },
  popperModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px 16px',
  },
  popperQuickStart: {
    height: '580px',
    zIndex: 801,
    marginTop: -150,
    [breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,
      height: '350px',
    },
  },
  categoryContainer: {
    height: '100%',
    maxWidth: 530,
    [breakpoints.down('lg')]: {
      width: '100%',
      minWidth: '100%',
    },
    backgroundColor: '#FFF',
    boxShadow: '0px 0px 4px rgba(0,0,0,0.25)',
    borderRadius: '0px 8px 8px 0px',
    display: 'flex',
    flexDirection: 'column',
  },
  categoryList: {
    display: 'flex',
    flex: 1,
    paddingLeft: '12px',
    flexDirection: 'column',
  },
  bottomContainer: {
    flex: 0,
    display: 'flex',
    padding: '16px 24px',
    justifyContent: 'space-between',
    borderTop: '1px solid #DEDDDD',
  },
  topContainer: {
    flex: 1,
    display: 'flex',
    padding: 12,
    overflow: 'auto',
  },
  categoryItemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  categoryHeader: {
    display: 'flex',
    padding: 12,
    paddingTop: 16,
    paddingLeft: 24,
    alignItems: 'center',
    borderBottom: '1px solid #DEDDDD',
  },

  // searchbar
  searchBar: {
    width: '100%',
    backgroundColor: '#F4F4F4',
    borderRadius: 6,
    padding: 8,
    border: '1px solid transparent',
    '&:hover': {
      boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    },
  },
  searchbarFocused: {
    backgroundColor: '#FFF',
    boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    border: `1px solid ${palette.primary.light}`,
  },
}));

function SubCategoryFilter({
  subCategoryId,
  handleClick = () => {},
  active = false,
  source,
}) {
  return (
    <BasicButton
      disableRipple
      sx={{
        marginTop: '8px',
        marginRight: '8px',
        backgroundColor: active ? 'primary.extraLight' : 'auto',
        color: active ? 'primary.main' : 'auto',
        borderColor: active ? 'primary.main' : 'auto',
        '&:hover': {
          backgroundColor: active ? 'primary.extraLight' : 'auto',
          color: active ? 'primary.main' : 'auto',
          borderColor: active ? 'primary.main' : 'auto',
        },
      }}
      id={`recommendations-filter-pill-${subCategoryId}`}
      className="recommendations-filter-pill"
      onClick={() => {
        if (source === 'suggestions') {
          phTrackEvent({
            event: `${EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_INITIAL_INTEREST}${subCategoryId}`,
          });
        } else if (source === 'subsequent_suggestions') {
          phTrackEvent({
            event: `${EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_SUBSEQUENT_INTEREST}${subCategoryId}`,
          });
        }
        handleClick({
          filterId: subCategoryId,
          action: active ? 'DELETE' : 'ADD',
        });
      }}>
      {recommendationCategories.ref[subCategoryId] &&
        recommendationCategories.ref[subCategoryId].name}
    </BasicButton>
  );
}

function CategoryItem({
  categoryId,
  subCategories,
  handleFilterChange,
  activeFilterIds = [],
  source,
}) {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className={classes.categoryItemContainer}>
      <div style={{ display: 'flex', width: '100%' }}>
        {collapsed ? (
          <ExpandMoreRounded
            style={{ cursor: 'pointer' }}
            onClick={() => setCollapsed(false)}
          />
        ) : (
          <ExpandLessRounded
            style={{ cursor: 'pointer' }}
            onClick={() => setCollapsed(true)}
          />
        )}

        <div>
          <Typography variant="h5Sub" marginLeft="8px" fontWeight={600}>
            {categoryId}
          </Typography>
        </div>
      </div>
      <Collapse in={collapsed}>
        <div className={classes.subCategoriesContainer}>
          {subCategories?.map((subCategoryId) => (
            <SubCategoryFilter
              handleClick={handleFilterChange}
              subCategoryId={subCategoryId}
              active={activeFilterIds.includes(subCategoryId)}
              source={source}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
}

function CategorySearchBar({ handleSubmit = () => {} }) {
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  return (
    <InputBase
      disableUnderline
      className={{
        [classes.searchBar]: true,
        [classes.searchbarFocused]: isFocused,
      }}
      placeholder="Search for filters"
      value={searchString}
      onChange={(e) => {
        setSearchString(e.target.value);
        handleSubmit(e.target.value);
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') handleSubmit(searchString);
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      inputProps={{
        style: {
          padding: 0,
        },
      }}
      startAdornment={
        <SearchRounded
          style={{
            color: '#8A8A8A',
            width: 16,
            height: 16,
            marginRight: 8,
            marginLeft: 4,
          }}
        />
      }
    />
  );
}

function CategorySelector({
  filterLabels,
  setFilterLabels,
  filterOpen,
  setFilterOpen,
  isQuickFilter = false,
  source = null,
}) {
  const classes = useStyles();
  const [currentFilterLabels, setCurrentFilterLabels] = useState([]);
  const [categorySearchValue, setCategorySearchValue] = useState('');

  // TODO: Improve search by splitting search terms within json
  const matchString = (searchKey, str) => {
    if (searchKey.trim() === '') return true;
    return (
      str
        ?.toLowerCase()
        ?.split(' ')
        ?.map((s) => s?.startsWith(searchKey.toLowerCase().trim()))
        ?.filter((s) => s)?.length > 0
    );
  };

  // TODO: Improve performance using react virtualized?
  const categoriesOrder = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(recommendationCategories.order)
          ?.map((mainCat) => {
            const x = [
              mainCat,
              recommendationCategories.order[mainCat]?.filter((subCat) =>
                matchString(
                  categorySearchValue,
                  recommendationCategories?.ref[subCat]?.name
                )
              ),
            ];
            return x;
          })
          ?.filter((l) => l[1]?.length > 0)
      ) || {},
    [categorySearchValue]
  );

  const handleFilterChange = ({ filterId, action }) => {
    let newFilterLabels = [...currentFilterLabels];
    if (action === 'RESET') newFilterLabels = [];
    else if (action === 'ADD') newFilterLabels.push(filterId);
    else
      newFilterLabels = newFilterLabels.filter((label) => label !== filterId);
    setCurrentFilterLabels(newFilterLabels);
  };

  useEffect(() => {
    setCurrentFilterLabels(filterLabels);
  }, [filterLabels]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobileSelector = isMobile && !isQuickFilter;
  const SelectorWrapper = isMobileSelector ? Modal : Popper;

  return (
    <SelectorWrapper
      open={Boolean(filterOpen)}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(174,174,174,0.6)',
          },
        },
      }}
      id="categories-popper"
      anchorEl={filterOpen}
      className={
        isQuickFilter
          ? classes.popperQuickStart
          : isMobileSelector
          ? classes.popperModal
          : classes.popper
      }
      placement={isQuickFilter ? 'bottom' : 'top-start'}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'viewport',
            padding: isQuickFilter ? 8 : 0,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'viewport',
            padding: isQuickFilter ? 8 : 0,
          },
        },
      ]}
      disablePortal>
      <div
        className={classes.categoryContainer}
        style={
          !isMobileSelector && !isQuickFilter && !isDesktop
            ? {
                maxWidth:
                  document.getElementById('map-container')?.clientWidth || 530,
              }
            : {}
        }>
        <div className={classes.categoryHeader}>
          <div style={{ flex: 1 }}>
            <CategorySearchBar
              handleSubmit={(value) => setCategorySearchValue(value)}
            />
          </div>
          <IconButton
            sx={{ padding: '4px', marginLeft: 1 }}
            size="small"
            onClick={() => {
              setFilterOpen(null);
            }}>
            <CloseRounded />
          </IconButton>
        </div>
        <div className={classes.topContainer}>
          <div className={classes.categoryList}>
            {Object.keys(categoriesOrder || {})?.map((categoryId, idx) => (
              <div style={{ marginTop: idx === 0 ? 0 : 24 }}>
                <CategoryItem
                  handleFilterChange={handleFilterChange}
                  categoryId={categoryId}
                  activeFilterIds={currentFilterLabels}
                  subCategories={categoriesOrder[categoryId]}
                  source={source}
                />
              </div>
            ))}
          </div>
        </div>
        {isQuickFilter ? (
          <div className={classes.bottomContainer}>
            <TertiaryButton
              id="recommendations-clear-filter-button"
              onClick={() => handleFilterChange({ action: 'RESET' })}
              disableRipple>
              {`Clear  ${
                currentFilterLabels?.length > 0
                  ? `(${currentFilterLabels.length})`
                  : ''
              }`}
            </TertiaryButton>
            <Button
              disabled={
                filterLabels.sort().join(',') ===
                currentFilterLabels.sort().join(',')
              }
              id="recommendations-show-results-button"
              onClick={() => {
                setFilterLabels([...currentFilterLabels]);
                setFilterOpen(null);
              }}>
              Save Interests
            </Button>
          </div>
        ) : (
          <div className={classes.bottomContainer}>
            <TertiaryButton
              id="recommendations-clear-filter-button"
              onClick={() => handleFilterChange({ action: 'RESET' })}
              disableRipple>
              {`Clear filters ${
                currentFilterLabels?.length > 0
                  ? `(${currentFilterLabels.length})`
                  : ''
              }`}
            </TertiaryButton>
            <Button
              disabled={
                filterLabels.sort().join(',') ===
                currentFilterLabels.sort().join(',')
              }
              id="recommendations-show-results-button"
              onClick={() => {
                if (source === 'recommendations')
                  phTrackEvent({
                    event: EVENTS.EXPLORE_ACTIVITIES.FILTERS_APPLY_CLICK,
                  });
                setFilterLabels([...currentFilterLabels]);
                if (isMobileSelector) setFilterOpen(null);
              }}>
              Show results
            </Button>
          </div>
        )}
      </div>
    </SelectorWrapper>
  );
}

export default CategorySelector;
