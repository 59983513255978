import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  List,
  ListItemText,
  Typography,
  ListItem,
  ListItemIcon,
} from '@mui/material';
import { LoadingIcon, Sparkles } from '../../../atoms/Icon';
import { EVENTS, phTrackEvent } from '../../../../analytics';

const useStyles = makeStyles({
  headers: {
    marginLeft: '8px',
    fontSize: '12px',
    color: '#aaaaaa',
    fontWeight: 150,
  },
  listItemContainer: {
    height: '40px',
    '&:hover': {
      backgroundColor: '#f4f4f4',
      borderRadius: '8px',
      color: '#000000',
    },
    padding: '8px 8px',
  },
  listItemText: {
    fontWeight: 300,
    '&:hover': {
      color: '#000000',
    },
  },
  listItemLetter: {
    fontWeight: 100,
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  focusedItem: {
    backgroundColor: '#f4f4f4',
    borderRadius: '8px',
    color: '#000000',
  },
});

function AskAi({ setActiveSection, value, setValue, setSuggestion }) {
  const classes = useStyles();
  const [focusedIndex, setFocusedIndex] = useState(0);

  const aiOptions = [
    {
      icon: (
        <LoadingIcon
          style={{
            width: '28px',
            height: '28px',
            marginLeft: '4px',
            color: '#8A8A8A',
          }}
        />
      ),
      text: 'Organize my trip',
      handleButtonClick: () => {},
      shortcut: 'Coming Soon',
      enabled: false,
    },
    {
      icon: (
        <Sparkles
          style={{
            width: '38px',
            height: '38px',
            color: '#000000',
          }}
        />
      ),
      text: 'Suggest ideas',
      handleButtonClick: () => {
        phTrackEvent({ event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_OPEN });
        phTrackEvent({ event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_CLICK });
        setActiveSection('suggestIdeas');
        setSuggestion('Activities');
        setValue('Suggest Ideas ');
      },
      // shortcut needs rework
      shortcut: '',
      enabled: true,
    },
  ];

  const filteredOptions = aiOptions.filter((option) =>
    option.text.toLowerCase().includes(value.toLowerCase())
  );

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      setFocusedIndex((prevIndex) =>
        prevIndex === filteredOptions.length - 1 ? 0 : prevIndex + 1
      );
    } else if (event.key === 'ArrowUp') {
      setFocusedIndex((prevIndex) =>
        prevIndex === 0 ? filteredOptions.length - 1 : prevIndex - 1
      );
    } else if (event.key === 'Enter') {
      if (filteredOptions[focusedIndex]) {
        filteredOptions[focusedIndex].handleButtonClick();
      }
    }
  };

  useEffect(() => {
    // Add event listener to handle keyboard events
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [focusedIndex, filteredOptions]);

  return (
    <Box>
      <Typography className={classes.headers}>Ask AI</Typography>
      <List>
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, index) => (
            <ListItem
              key={option.text}
              className={`${classes.listItemContainer} ${
                index === focusedIndex && option.enabled
                  ? classes.focusedItem
                  : ''
              }`}
              onClick={option.handleButtonClick}>
              <ListItemIcon
                sx={{
                  minWidth: 40,
                  color: option.enabled ? 'inherit' : '#8A8A8A',
                }}>
                {option.icon}
              </ListItemIcon>
              <ListItemText
                primary={option.text}
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 300,
                    color: option.enabled ? 'inherit' : '#8A8A8A',
                    '&:hover': {
                      color: option.enabled ? 'inherit' : '#8A8A8A',
                    },
                  },
                }}
              />
              {option.shortcut !== '' && (
                <Box
                  sx={{
                    border: '1px solid #D9D9D9',
                    borderRadius: '4px',
                    width: option.enabled ? '18px' : '80px',
                    height: '18px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Typography
                    variant="h6"
                    className={classes.listItemLetter}
                    sx={{
                      color: option.enabled ? 'inherit' : '#8A8A8A',
                    }}>
                    {option.shortcut}
                  </Typography>
                </Box>
              )}
            </ListItem>
          ))
        ) : (
          <Typography
            sx={{
              padding: '8px',
              color: '#000000',
              textAlign: 'center',
            }}>
            No results found
          </Typography>
        )}
      </List>
    </Box>
  );
}

export default AskAi;
